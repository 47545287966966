import env from "../environment/env";
import { apiFetch, checkStatus } from "./utils";

const webAppUrl = env.WEBAPP_API;

export interface UserInfo {
  clientNumber: string;
  transactionIns: TransactionIn[];
  transactionOuts: TransactionOut[];
  hasSeenTutorial: boolean;
  totalItemCount: number;
}

export interface TransactionIn {
  in_or_out: "in";
  createdAt: string;
  nbOfContents: number;
  value: number;
}

export interface TransactionOut {
  in_or_out: "out";
  createdAt: string;
  value: number;
  service?: string;
}

export interface GeneralStats {
  userCount: number;
  wasteCount: number;
  wasteWeight: number;
  bin: number;
}

export interface WebAppApi {
  getUserInfo: (token: string) => Promise<UserInfo | null>;
  setUserHasSeenTutorial: (token: string) => Promise<void>;
  getGeneralStats: () => Promise<GeneralStats | null>;
}

export const webAppApi: WebAppApi = {
  getUserInfo: async (token) => {
    try {
      const res = await apiFetch(`${webAppUrl}/user/info`, {
        method: "GET",
        headers: { authorization: `Bearer ${token}` },
      });

      checkStatus(res);

      return await res.json();
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  setUserHasSeenTutorial: async (token) => {
    try {
      const res = await apiFetch(`${webAppUrl}/user/setUserHasSeenTutorial`, {
        method: "PATCH",
        headers: { authorization: `Bearer ${token}` },
      });

      checkStatus(res);

      return await res.json();
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  getGeneralStats: async () => {
    try {
      const res = await apiFetch(`${webAppUrl}/stats/general`, {
        method: "GET",
      });
      checkStatus(res);
      return await res.json();
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};
